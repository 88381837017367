<!-- Coming Soon Area -->
<div class="construction">
    <div class="navbar-area">
        <div class="container">
            <div class="navbar-menu">
                <div class="row align-items-center">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="logo">
                            <a routerLink="/"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div class="burger-menu" (click)="toggleClass()">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <h5>We Are Coming</h5>
                        <h1>Coming soon...</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitlabore et dolore magna aliquaolor sit amet consectetur.</p>
                        <div id="timer">
                            <div id="days">
                                {{days}} <span>Days</span>
                            </div>
                            <div id="hours">
                                {{hours}} <span>Hours</span>
                            </div>
                            <div id="minutes">
                                {{minutes}} <span>Minutes</span>
                            </div>
                            <div id="seconds">
                                {{seconds}} <span>Seconds</span>
                            </div>
                        </div>
                        <button class="notify-btn" (click)="toggleClass2()">Notify Me Update</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
        <footer class="footer-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <p>© Ngcont is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li><span>Stay Connect:</span></li>
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>

<!-- Sidebar Modal -->
<div class="sidebar-modal" [class.active]="classApplied">
    <div class="sidebar-modal-inner">
        <div class="about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis suspendisse ultrices gravida. Risus commodo viverra. Quis suspendisseeee ultrices gravida.</p>
            </div>
        </div>
        <div class="contact-area">
            <div class="title">
                <h2>Contact Us</h2>
            </div>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="form-group mb-3">
                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                    </div>
                    <div class="form-group mb-3">
                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                    </div>
                    <div class="form-group mb-3">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                    </div>
                    <button type="submit">Send Message</button>
                </form>
            </div>
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Contact us by Phone Number or Email Address</h3>
                    <h2>
                        <a href="tel:+8885414575412">+888 541 457 5412</a>
                        <span>OR</span>
                        <a href="mailto:hello&#64;ngcont.com">hello&#64;ngcont.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn" (click)="toggleClass()"><i class="fas fa-times"></i></span>
    </div>
</div>

<!-- Notify Modal -->
<div class="subscribe-modal" [class.active]="classApplied2">
    <div class="subscribe-modal-inner">
        <div class="subscribe-modal-content">
            <div class="newsletter-header">
                <div class="animation-icons">
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                    <span class="animate-icon"><i class="far fa-envelope"></i></span>
                </div>
                <img src="assets/img/bell-icon.png" alt="image">
            </div>
            <h2>Subscribe Newsletter</h2>
            <p>Being the first to know always feels great. Signing up to our newsletter gives you exclusive access to our Grand Opening!</p>
            <form class="newsletter-form">
                <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL">
                <button type="submit">Subscribe Now</button>
            </form>
            <span class="close-btn subscribe-modal-close-btn" (click)="toggleClass2()"><i class="fas fa-times"></i></span>
        </div>
    </div>
</div>