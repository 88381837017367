<div class="restaurant-3">
    <div class="navbar-area">
        <div class="container">
            <div class="navbar-menu">
                <div class="logo">
                    <a routeLink="/restaurant-3"><img src="assets/img/black-logo.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="count-down-timer">
                                <div id="timer">
                                    <div id="days">
                                        {{days}} <span>Days</span>
                                    </div>
                                    <div id="hours">
                                        {{hours}} <span>Hours</span>
                                    </div>
                                    <div id="minutes">
                                        {{minutes}} <span>Minutes</span>
                                    </div>
                                    <div id="seconds">
                                        {{seconds}} <span>Seconds</span>
                                    </div>
                                </div>
                            </div>
                            <div class="main-banner-content">
                                <h1>Something big is coming soon</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiu sit amet consectetur adipisicing.</p>
                                <form class="newsletter-form">
                                    <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL">
                                    <button type="submit">Notify Me</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>