<div class="creative-4">
    <section class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <div class="logo">
                            <a routerLink="/creative-4">
                                <img src="assets/img/black-logo.png" alt="logo">
                            </a>
                        </div>
                        <h1>We are Coming Soon</h1>
                        <div id="timer">
                            <div id="days">
                                {{days}} <span>Days</span>
                            </div>
                            <div id="hours">
                                {{hours}} <span>Hours</span>
                            </div>
                            <div id="minutes">
                                {{minutes}} <span>Minutes</span>
                            </div>
                            <div id="seconds">
                                {{seconds}} <span>Seconds</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="wrapper">
            <div id="clouds"></div>
            <div id="ground"></div>
        </div>
    </section>
</div>