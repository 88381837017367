<div class="creative-1">
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <div class="logo">
                            <a routerLink="/creative-1"><img src="assets/img/black-logo.png" alt="image"></a>
                        </div>
                        <div id="timer">
                            <div id="days">
                                {{days}} <span>Days</span>
                            </div>
                            <div id="hours">
                                {{hours}} <span>Hours</span>
                            </div>
                            <div id="minutes">
                                {{minutes}} <span>Minutes</span>
                            </div>
                            <div id="seconds">
                                {{seconds}} <span>Seconds</span>
                            </div>
                        </div>
                        <h2>Something big is coming soon</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <form class="newsletter-form">
                            <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL">
                            <button type="submit">Notify Me</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer-area">
            <div class="container">
                <ul>
                    <li><span>Stay Connected:</span></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </footer>
        <div class="shape1 wow fadeInRight slow"><img src="assets/img/shape3.png" alt="image"></div>
        <div class="shape2 wow fadeInUp slow"><img src="assets/img/shape4.png" alt="image"></div>
        <div class="dot-shape1"><img src="assets/img/dot1.svg" alt="image"></div>
        <div class="dot-shape2"><img src="assets/img/dot2.png" alt="image"></div>
        <div class="dot-shape3"><img src="assets/img/dot3.png" alt="image"></div>
        <div class="dot-shape4"><img src="assets/img/dot4.png" alt="image"></div>
        <div class="dot-shape5"><img src="assets/img/dot5.png" alt="image"></div>
        <div class="dot-shape6"><img src="assets/img/dot6.png" alt="image"></div>
        <div class="dot-shape7"><img src="assets/img/dot7.png" alt="image"></div>
    </div>
</div>